/* eslint-disable camelcase */
import { Button, Form, message, Space } from 'antd';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { useUser } from '../../redux/user/selectors';
import { fetchCalendarCompany, fetchCalendlyData, fetchCalendlyInvitees } from '../../services/api';
import { fetchMedicalFileData } from '../../redux/medicalFile/actions';
import request from '../../services/request';
import { fetchStep } from '../../redux/step/actions';
import SelectCities from '../form/SelectCities';
import SelectCenters from '../form/SelectCenters';

function CalendlyForm() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const [calendarLabel, setCalendarLabel] = useState(null);
  const [centerSelected, setCenterSelected] = useState(null);
  const [citySelected, setCitySelected] = useState(null);

  const [user] = useUser();
  const [medicalFile] = useMedicalFile();
  const [[isUpdating, updateErr], setIsUpdating] = useState([false, null]);

  const updateTravelData = async (value, start) => {
    setIsUpdating([true, null]);
    return request(`/medicalfiles/${medicalFile.reference}/traveldata`, 'POST', {
      calendar_link: value,
      hospital: centerSelected,
      start,
    })
      .then(() => {
        setIsUpdating([false, null]);
        dispatch(fetchStep(medicalFile.reference));
        dispatch(fetchMedicalFileData(medicalFile.reference));
      })
      .catch((err) => {
        message.error(t('FRONT_NOTIFICATION_UPDATE_FAILED'));
        setIsUpdating([false, err]);
      });
  };

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      const { uri } = e.data.payload.event;
      const uuid = uri.substring(uri.lastIndexOf('/') + 1);
      const event = await fetchCalendlyData(uuid);
      const { start_time } = event.resource;
      await updateTravelData(uuid, start_time);
    },
  });

  const { event, meetup, calendar_link } = medicalFile.traveldata || {};

  useEffect(() => {
    const getCalendarLabel = async () => {
      const labels = await fetchCalendarCompany(centerSelected);
      return labels ? labels.find((c) => c.id === medicalFile.checkup_type) : null;
    };
    if (centerSelected) getCalendarLabel().then((label) => setCalendarLabel(label.value || null));
  }, [centerSelected, medicalFile.checkup_type]);

  const createOrUpdateEvent = () => {
    // eslint-disable-next-line no-shadow
    if (calendarLabel) {
      const calendarUrl = `https://calendly.com/francesurgery/${calendarLabel}`;
      setUrl(calendarUrl);
      setOpen(true);
    }
  };

  const cancelEvent = async () => {
    setUrl(meetup.cancel_url);
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
    if (meetup && meetup.cancel_url === url) {
      const invitees = await fetchCalendlyInvitees(calendar_link);
      if (invitees.collection && invitees.collection[0].status === 'canceled')
        updateTravelData(null);
    }
  };

  if (meetup)
    return (
      <div style={{ textAlign: 'center' }}>
        <p>
          {`${t('FRONT_COMMON_FROM')} ${new Date(event.start_time).toLocaleString()} ${t(
            'FRONT_COMMON_UNTIL'
          )} ${new Date(event.end_time).toLocaleString()}`}
        </p>
        <Space>
          <Button type="primary" onClick={cancelEvent} danger loading={isUpdating}>
            Annuler le rendez-vous
          </Button>
        </Space>
        <PopupModal
          url={url}
          textColor="#ffffff"
          color="#00a2ff"
          prefill={{
            email: user.email,
            firstName: user.firstname,
            lastName: user.lastname,
            name: `${user.firstname} ${user.lastname}`,
            smsReminderNumber: user.phone,
          }}
          onModalClose={handleClose}
          open={isOpen}
          rootElement={document.getElementById('root')}
        />
      </div>
    );
  return (
    <div style={{ textAlign: 'center' }}>
      <h3>Formulaire de prise de rendez-vous</h3>
      <Form
        form={form}
        onValuesChange={async (changedValues) => {
          if (changedValues.city !== undefined) {
            setCitySelected(changedValues.city);
            setCenterSelected(false);
            await form.setFieldsValue({ center_id: null });
            await form.validateFields(['center_id']);
          }
          if (changedValues.center_id !== undefined) {
            setCenterSelected(changedValues.center_id);
          }
        }}
      >
        <SelectCities />
        {citySelected && (
          <SelectCenters checkupType={medicalFile.checkup_type} city={citySelected} />
        )}
      </Form>
      {calendarLabel && calendarLabel !== 'unfounded' && (
        <Button
          type="primary"
          onClick={createOrUpdateEvent}
          style={{ margin: 'auto' }}
          loading={isUpdating}
        >
          Prendre rendez-vous
        </Button>
      )}
      {!calendarLabel && centerSelected && (
        <p>La clinique sélectionné ne prend pas en charge ce type de bilan.</p>
      )}
      <PopupModal
        url={url}
        textColor="#ffffff"
        color="#00a2ff"
        prefill={{
          email: user.email,
          firstName: user.firstname,
          lastName: user.lastname,
          name: `${user.firstname} ${user.lastname}`,
          smsReminderNumber: user.phone,
        }}
        onModalClose={handleClose}
        open={isOpen}
        rootElement={document.getElementById('root')}
      />
    </div>
  );
}

export default CalendlyForm;
